import React from "react";

import { useParams } from 'react-router-dom'
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import { appId, secretKey } from '../../constant';


const Roompage = () => {
    const { roomcode, userName } = useParams();

    const tempNameForJoin = "'";
    var currentPath = window.location.pathname;

    // Use a regular expression to extract the desired part
    var match = currentPath.match(/^\/room\/\d+/);

    // Check if there's a match and get the result
    const mainUrlPath = match ? match[0] : null;


    const myMeeting = async (element) => {
        const applicationId = appId;
        const roomId = roomcode;
        const secretId = secretKey;
        const testToken = ZegoUIKitPrebuilt.generateKitTokenForTest(applicationId, secretId, roomId, Date.now().toString(), userName);

        const zp = ZegoUIKitPrebuilt.create(testToken);
        zp.joinRoom({
            container: element,
            showTurnOffRemoteCameraButton: true,
            showTurnOffRemoteMicrophoneButton: true,
            scenario: {
                mode: ZegoUIKitPrebuilt.VideoConference
            },
            sharedLinks: [
                {
                    name: 'Join Meeting URL',
                    url:
                        window.location.protocol + '//' +
                        window.location.host + mainUrlPath + '/' + tempNameForJoin
                },],
            onJoinRoom: async () => {
                // Add your custom logic
                await fetch('https://testapiwizkind.yaahr.com/event/zegocloud_start_recording', {
                    method: 'POST',
                    body: JSON.stringify({
                        RoomId: roomId
                    }),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
                    .then((response) => response.json())
                    .then((data) => {
                        localStorage.setItem('taskId', data.Data.TaskId);
                        fetch('https://testapiwizkind.yaahr.com/event/zego_cloud_updatelayout', {
                    method: 'POST',
                    body: JSON.stringify({
                        RoomId: roomId,
                        TaskId: data.Data.TaskId
                    }),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
                    .then((response) => response.json())
                    .catch((err) => {
                        console.log('join error roomid' + roomId + ' msg ' + err.message);
                    });
                    })
                    .catch((err) => {
                        console.log('join error roomid' + roomId + ' msg ' + err.message);
                    });
            },
            showLayoutButton:true,
            onLeaveRoom: async () => {
                var taskId = localStorage.getItem('taskId');
                await fetch('https://testapiwizkind.yaahr.com/event/zego_cloud_recording', {
                    method: 'POST',
                    body: JSON.stringify({
                        TaskId: taskId,
                        Type: "stop_recording",
                        RoomId: roomId
                    }),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
                    .then((response) => response.json())
                    .then((data) => {
                    })
                    .catch((err) => {
                        console.log('leave error roomid' + roomId + ' msg ' + err.message);
                    });
            },
            onUserJoin: (users) => {
            },
            onUserLeave: (users) => {
            }
        });

        // // // zp.express.on('roomUserUpdate', (roomID, updateType, userList) => {
        // // //     console.log('roomUserUpdate roomID ', roomID, userList);
        // // //     if (updateType === 'ADD') {
        // // //         alert('Room Add')
        // // //     } else if (updateType == 'DELETE') {
        // // //         alert('Room delete')
        // // //     }
        // // // });
    };

    return (
        // <div className="room-page">
        //     <div ref={myMeeting} />
        // </div>

        <div className="myCallContainer"
            ref={myMeeting}
            style={{ width: '100vw', height: '100vh' }}
        ></div>

    )
}

export default Roompage;