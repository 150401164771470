import * as React from 'react';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';

import { appId, secretKey } from '../../constant';

import { useParams } from 'react-router-dom'

function randomID(len) {
  let result = '';
  if (result) return result;
  var chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP',
    maxPos = chars.length,
    i;
  len = len || 5;
  for (i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return result;
}

export function getUrlParams(
  url = window.location.href
) {
  let urlStr = url.split('?')[1];
  return new URLSearchParams(urlStr);
}

export default function App() {

    
    const { roomcode,urole,uname } = useParams();
    var currentPath = window.location.pathname;
    const UserId='user_'+uname || randomID(5);
    const UserName = uname || randomID(5);
    const tempNameForJoin="'";

// Use a regular expression to extract the desired part
// var match = currentPath.match(/^\/livestream\/\d+/)
// Check if there's a match and get the result
// const mainUrlPath = match ? match[0] : null;
var newroomCode=roomcode;
if(urole==="Host"){
  newroomCode = roomcode+randomID(5)
}
  const roomID = newroomCode || randomID(5);
  ;
const mainUrlPath =  currentPath.split('/')[1]+'/'+roomID;
  let role_str = urole || 'Host';
  const role =
    role_str === 'Host'
      ? ZegoUIKitPrebuilt.Host
      : role_str === 'Cohost'
      ? ZegoUIKitPrebuilt.Cohost
      : ZegoUIKitPrebuilt.Audience;

  let sharedLinks = [];
  if (role === ZegoUIKitPrebuilt.Host || role === ZegoUIKitPrebuilt.Cohost) {
    sharedLinks.push({
      name: 'Join as co-host',
      url:
        window.location.protocol + '//' + 
        window.location.host +"/" +mainUrlPath +
        '/Cohost/'+tempNameForJoin,
    });
  }
  sharedLinks.push({
    name: 'Join as audience',
    url:
     window.location.protocol + '//' + 
     window.location.host +"/" +mainUrlPath +
      '/Audience/'+tempNameForJoin,
  });
 // generate Kit Token
  const appID = appId;
  const serverSecret = secretKey;
  const kitToken =  ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID,  UserId, UserName);


  // start the call
  let myMeeting = async (element) => {
      // Create instance object from Kit Token.
      const zp = ZegoUIKitPrebuilt.create(kitToken);
      // start the call
      zp.joinRoom({
        container: element,
        scenario: {
          mode: ZegoUIKitPrebuilt.LiveStreaming,
          config: {
            role,
          },
        },
        sharedLinks,
      });
  };

  return (
    <div
      className="myCallContainer"
      ref={myMeeting}
      style={{ width: '100vw', height: '100vh' }}
    ></div>
  );
}