import React, { useState } from "react";

import { useParams } from 'react-router-dom'
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import { appId, secretKey } from '../../constant';
import { ZIM } from "zego-zim-web";
import 'bootstrap/dist/css/bootstrap.min.css';


// Generate a Token by calling a method.
// @param 1: appID
// @param 2: serverSecret
// @param 3: Room ID
// @param 4: User ID
// @param 5: Username




const Videocall = () => {
    const [userIds, setuserIds] = useState('');
    const { userNameId } = useParams();
    const userID = userNameId;
    const appID = appId;
    const serverSecret = secretKey;
    const userName = 'user_' + userID;

    const TOKEN = ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, null, userID, userName);



    const zp = ZegoUIKitPrebuilt.create(TOKEN);

    zp.setCallInvitationConfig({
        // The callee will receive the notification through this callback when receiving a call invitation.
        onIncomingCallReceived: (callID, caller, callType, callees) => {
            console.log('abcd onIncomingCallReceived' + callID + ' caller ' + caller.userName + ' callType ' + callType + ' callees' + callees[0].userName);
        },

        // The callee will receive the notification through this callback when the caller canceled the call invitation.  
        onIncomingCallCanceled: (callID, caller) => {
            console.log('abcd missed call' + callID + ' caller' + caller);
        },

        // The callee will receive the notification through this callback when the caller accepts the call invitation. 
        onOutgoingCallAccepted: (callID, callee) => {
            console.log('abcd onOutgoingCallAccepted');
        },

        // The caller will receive the notification through this callback when the callee is on a call.
        onOutgoingCallRejected: (callID, callee) => {
            console.log('abcd onOutgoingCallRejected');
        },

        // The caller will receive the notification through this callback when the callee declines the call invitation. 
        onOutgoingCallDeclined: (callID, callee) => {
            console.log('abcd onOutgoingCallDeclined');
        },

        // The callee will receive the notification through this callback when he didn't respond to the call invitation. 
        onIncomingCallTimeout: (callID, caller) => {
            console.log('abcd onIncomingCallTimeout');
        },

        // The caller will receive the notification through this callback when the call invitation timed out.
        onOutgoingCallTimeout: (callID, callees) => {
            console.log('abcd onOutgoingCallTimeout');
        }
    });

    zp.addPlugins({ ZIM });


    const invite = (event) => {
        event.preventDefault();
        const users = {
            userID: userIds,
            userName: 'user_' + userIds,
        }
        // send call invitation
        zp.sendCallInvitation({
            //callees: users,
            callees: [users],
            callType: ZegoUIKitPrebuilt.InvitationTypeVideoCall,
            timeout: 60,
        })
            .then((res) => {
                if (res.errorInvitees.length) {
                    alert('The user dose not exist or is offline.');
                }
            })
            .catch((err) => {
            });

    }
    const handleInputChange = (event) => {
        // Update the state with the new input value
        setuserIds(event.target.value);
    };

    return (
        <div className="room-page">


            <div class="card bg-dark text-white float-right m-4">
                <h2 class="d-flex justify-content-center">UserName :  {userID}</h2>
                <div class="card-body">
                    <form>
                        <div class="mb-3">
                            <input type="text" class="form-control" id="userIds" value={userIds} onChange={handleInputChange} placeholder="Enter UserName" required />
                        </div>
                        <button type="submit" class="btn btn-primary btn-block mb-4" onClick={invite} >Call Now</button>
                    </form>
                </div>
            </div>

        </div>
    )
}

export default Videocall;