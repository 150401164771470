
import './App.css';
import {Routes,Route} from 'react-router-dom';

import HomePage from './Pages/Home';
import Roompage from './Pages/Room';
import Videocall from './Pages/VideoCall';
import Livepage from './Pages/LiveStream';

function App() {
  return (
  <Routes>
    <Route path="/" element={<HomePage/>}></Route>
    <Route path="/livestream/:roomcode/:urole/:uname" element={<Livepage/>}></Route>
    <Route path="/room/:roomcode/:userName" element={<Roompage/>}></Route>
  
    <Route path="/call/:userNameId" element={<Videocall/>}></Route>
   </Routes>
  );
}

export default App;
