import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';



const HomePage = () => {

    const [roomId, setroomId] = useState();

    const [userName, setuserName] = useState();
    const [isChecked, setIsChecked] = useState(false);

    const navigate = useNavigate();

    const handleCheckboxChange = () => {
        // Update the state when the checkbox is clicked
        setIsChecked(!isChecked);
      };


      const handleInputChange = (event) => {
        const value = event.target.value;
    
        // Define a regular expression that allows only letters, numbers, and underscores
        const regex = /^[a-zA-Z0-9_]*$/;
    
        // Check if the input matches the regular expression
        if (regex.test(value)) {
          setroomId(value);
        }
        else{
            alert('Do Not Use Special Character')
            return
        }
        // If the input contains special characters, don't update the state
      };


    const handleSubmit = (event) => {
        //   event.preventDefault();
        if (event === 'Room') {
            if(isChecked){
                navigate(`/livestream/${roomId}/Host/${userName}`);
             }
            else{
                navigate(`/room/${roomId}/${userName}`);
   
            }
        } else {
            navigate(`/call/${userName}`);
        }
    }

    return (


        <div className="home-page gradient-custom ">

            <div class="card bg-dark text-white float-left m-4">
                <h3 class="d-flex justify-content-center">Create Room</h3>
                <div class="card-body">
                    <h5 class="card-title">Enter Room Code</h5>
                    <form>
                        <div class="mb-3">
                            {/* <label for="username" class="form-label">Enter Room Code</label> */}
                            <input type="text" class="form-control" id="username" value={roomId} onChange={handleInputChange} placeholder="Enter Room Code" required />
                            <input type="text" class="form-control mt-3" id="username" value={userName} onChange={(e) => setuserName(e.target.value)} placeholder="Enter User Name" required />
                            <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                            /> Is Live Streaming Room
                        </div>
                        <button type="submit" class="btn btn-primary btn-block mb-4" onClick={(e) => { e.preventDefault(); handleSubmit('Room'); }} >Join Room</button>
                    </form>
                </div>
            </div>
            <div class="card bg-dark text-white float-right m-4">
                <h2 class="d-flex justify-content-center">Make a call</h2>
                <div class="card-body">
                    <h5 class="card-title">Create Profile</h5>
                    <form>
                        <div class="mb-3">
                            {/* <label for="username" class="form-label">Enter Room Code</label> */}
                            {/* <input type="text" class="form-control" id="username" value={roomId} onChange={(e) => setroomId(e.target.value)} placeholder="Enter Room Code" required /> */}
                            <input type="text" class="form-control mt-3" id="username" value={userName} onChange={(e) => setuserName(e.target.value)} placeholder="Enter User Name" required />
                        </div>
                        <button type="submit" class="btn btn-primary btn-block mb-4" onClick={(e) => { e.preventDefault(); handleSubmit('Call'); }} >Save</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default HomePage